<template>
  <div class="w-100">
      <b-card-code class="m-0 pb-2 w-100">
        <h2 class="text-center pb-2">FAQ</h2>

        <app-collapse accordion>
          <app-collapse-item title="What is Mental Health Check?" :isVisible="true">
            Mental Health Check is a tool that people can use to try and manage their own mental health. We have integrated common issues/situations; mental health research and psychological theory into simple questionnaires which you can take to help you dissect your own mental health. These can be used as often as needed along your journey, to keep track of your progress.
          </app-collapse-item>
          <app-collapse-item title="Can Mental Health Check give me a diagnosis?">
            No. You can only get a diagnosis from a medical doctor after a thorough assessment. Mental Health Check is for your own dissection of your mental distress. All our advice and information is rooted firmly in science and research and we are confident in our content. However, if you would like an official assessment you will need to see your doctor/psychiatrist/psychologist.
          </app-collapse-item>
          <app-collapse-item title="My Mental Health Score is low, does this mean I am mentally unwell?">
            No. Dealing with mental distress is a normal part of life. We all experience situations where we struggle to cope and may need some support or guidance. For many people they will never receive an official diagnosis because they do not meet the criteria threshold. This does not mean it is not helpful to understand your emotions and use psychological and therapeutic tools to manage your mental health. This is an important part of staying mentally healthy.
          </app-collapse-item>
          <app-collapse-item title="My Mental Health Score is high but I don't feel like I am coping with my mental health.">
            Our scores are for guidance purposes only. You are the best person to judge your own mental well-being. Our website is open for everyone to access support and advice so regardless of your score, please use it.
          </app-collapse-item>
          <app-collapse-item title="If I use Mental Health Check does that mean I don't need professional help?">
            No. Mental Health Check is to be used as a supplement to any professional help you may require. If at any time you need more support we advise you speak to your doctor as soon as possible.
          </app-collapse-item>
          <app-collapse-item title="How is my score calculated?">
            Your score is calculated using percentages in 3 different areas of health. These are biological (physical), psychological and social. A combination of these 3 aspects of our life contribute to our health and that includes our mental health. It is important to consider all of these factors when understanding our mental health so we can work on every area we are having difficulties in.
          </app-collapse-item>
          <app-collapse-item title="Can I trust the advice given on Mental Health Check?">
            We are passionate about mental health and want to provide a service that is open to everyone to access support, guidance, tips and information. Our priority is to ensure that all our content is rooted firmly in mental health science and research. We only provide content that we trust and believe in, overseen by qualified mental health experts. We are located in the UK so our vantage point is western psychology and health.
          </app-collapse-item>
          <app-collapse-item title="I am being held under the Mental Health Act (1983), can I still use Mental Health Check?">
            Everybody is welcome to use Mental Health Check, however we do not address issues regarding the Mental Health Act. We would advise using the available support and treatment you are given by your hospital.
          </app-collapse-item>
          <app-collapse-item title="How is my mental health data being used?">
            You mental health data is used for no other purposes than your personal score. You also have the option to remain anonymous to all other users on the platform. Our platform is monitored to keep everyone safe as we are aware that some people may be vulnerable, but this is all kept confidential.
          </app-collapse-item>
        </app-collapse>

      </b-card-code>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
  },
};
</script>
